import _ from "lodash"
import React from "react"
import marked from "marked"
import { parseRawHtml as parse } from "../utils"

import {
  Accordion,
  Container,
  Header,
  Icon,
  Image,
  Segment,
  Sidebar,
  Grid,
  Menu,
} from "semantic-ui-react"
import ResponsiveLayout from "../components/responsive-layout"
import SocialShare from "../components/social-share";
import "../components/styles.css"

import PageContent from "frontmatter-markdown-loader?modules!../../content/how-can-constitutions-advance-equality.md"
import { attributes as caseTopicsData } from "frontmatter-markdown-loader?modules!../../content/data/cases-by-topic.md"
import { attributes as caseResourcesAndDatabasesData } from "frontmatter-markdown-loader?modules!../../content/data/case-resources-and-databases.md"

const caseTopics = caseTopicsData.caseTopics
const caseResourcesAndDatabases = caseResourcesAndDatabasesData.caseResourcesAndDatabases


export default ({ data = PageContent.attributes }) => (
  <ResponsiveLayout title={data.title}>
    <Page content={data} />
  </ResponsiveLayout>
)

const Page = ({ content, isMobile }) => (
  <Container>

    <Segment basic id="why-constitutions" style={{ marginTop: isMobile ? "2em" : "4em", padding: "1em" }}>

      <Header as="h2" dividing>
        {content.whyConstitutionsSection.title}
        <SocialShare config={content.whyConstitutionsSection.social} float="right" />
      </Header>

      <Segment basic
        className="MkdContent"
        style={{
          padding: "0em",
          marginTop: "3em"
        }}
        children={
          <>
            <Image rounded bordered size="medium"
              src={content.whyConstitutionsSection.image}
              floated={!isMobile && "right"}
              style={{
                marginBottom: isMobile ? "2em" : "unset"
              }}
            />
            {parse(marked(content.whyConstitutionsSection.introduction))}
          </>
        }
      />

    </Segment>

    <Segment basic id="constitutions-questions-and-answers" style={{ marginTop: isMobile ? "2em" : "6em", padding: "1em" }}>

      <Header as="h2" dividing>
        {content.qnaSection.title}
        <SocialShare config={content.qnaSection.social} float="right" />
      </Header>

      <QnaEntries qnaEntries={content.qnaSection.qnaEntries} isMobile={isMobile} />

    </Segment>

    <Segment basic id="constitutional-cases-from-around-the-world" style={{ marginTop: isMobile ? "3em" : "5em", padding: "1em" }}>

      <Header as="h2" dividing>
        {content.constitutionalCasesFromAroundTheWorldSection.title}
        <SocialShare config={content.constitutionalCasesFromAroundTheWorldSection.social} float="right" />
      </Header>

      <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "2em" }}>
        {parse(marked(content.constitutionalCasesFromAroundTheWorldSection.introduction))}
      </Segment>

      <Segment basic style={{ padding: "0em" }}>
        <ConstitutionalCases data={content} isMobile={isMobile} />
      </Segment>

    </Segment>

  </Container>
)

class QnaEntries extends React.Component {
  state = {
    activeQnaEntryIndices: {
    }
  }
  toggleQnaEntriesVisibility = (e, titleProps) => {
    if (this.state.activeQnaEntryIndices[titleProps.index]) {
      delete this.state.activeQnaEntryIndices[titleProps.index]
    }
    else {
      this.state.activeQnaEntryIndices[titleProps.index] = true
    }
    this.setState({
      activeQnaEntryIndices: this.state.activeQnaEntryIndices
    })
  }
  render() {
    return (
      <Accordion fluid styled style={{ marginTop: "3em" }}>
        {this.props.qnaEntries.map((qnaEntry, i) => (
          <>
            <Accordion.Title
              index={i}
              // active={!!this.state.activeQnaEntryIndices[i]}
              active={true}
              onClick={this.toggleQnaEntriesVisibility}
              style={{ fontSize: "1.1em" }}
            >
              <Icon name="dropdown" />
              {qnaEntry.question}
            </Accordion.Title>
            <Accordion.Content active={!!this.state.activeQnaEntryIndices[i]}>
              <Segment basic className="MkdContent"
                style={{
                  paddingLeft: this.props.isMobile && "0em",
                  paddingRight: this.props.isMobile && "0em"
                }}
              >
                {qnaEntry.answer && parse(marked(qnaEntry.answer))}
              </Segment>
            </Accordion.Content>
          </>
        ))}
      </Accordion>
    )
  }
}

class ConstitutionalCases extends React.Component {
  state = {
    isCaseTopicsSubsectionActive: true,
    isCaseResourcesAndDatabasesSubsectionActive: false,
    activeCaseTopicIndices: {
    },
    activeCaseResourcesAndDatabasesIndices: {
    }
  }
  toggleActiveCaseTopics = (e, titleProps) => {
    if (this.state.activeCaseTopicIndices[titleProps.index]) {
      delete this.state.activeCaseTopicIndices[titleProps.index]
    }
    else {
      this.state.activeCaseTopicIndices[titleProps.index] = true
    }
    this.setState({
      activeCaseTopicIndices: this.state.activeCaseTopicIndices
    })
  }
  toggleActiveCaseResourcesAndDatabases = (e, titleProps) => {
    if (this.state.activeCaseResourcesAndDatabasesIndices[titleProps.index]) {
      delete this.state.activeCaseResourcesAndDatabasesIndices[titleProps.index]
    }
    else {
      this.state.activeCaseResourcesAndDatabasesIndices[titleProps.index] = true
    }
    this.setState({
      activeCaseResourcesAndDatabasesIndices: this.state.activeCaseResourcesAndDatabasesIndices
    })
  }
  render() {
    return (
      <Accordion fluid styled style={{ marginTop: "3em" }}>
        <Accordion.Title
          // active={this.state.isCaseTopicsSubsectionActive}
          active={true}
          onClick={() => this.setState({
            isCaseTopicsSubsectionActive: !this.state.isCaseTopicsSubsectionActive
          })}
          style={{ fontSize: "1.1em" }}
        >
          <Icon name="dropdown" />
          {this.props.data.constitutionalCasesFromAroundTheWorldSection.caseTopicsSubsection.title}
        </Accordion.Title>
        <Accordion.Content
          active={this.state.isCaseTopicsSubsectionActive}
        >
          <Segment basic padded={!this.props.isMobile} style={{ paddingLeft: this.props.isMobile && "0em", paddingRight: this.props.isMobile && "0em" }}>
            <Segment basic className="MkdContent" style={{ padding: "0em" }}>
              {
                this.props.data.constitutionalCasesFromAroundTheWorldSection.caseTopicsSubsection.image &&
                <Image rounded bordered floated="left" size="tiny"
                  src={this.props.data.constitutionalCasesFromAroundTheWorldSection.caseTopicsSubsection.image}
                />
              }
              {
                this.props.data.constitutionalCasesFromAroundTheWorldSection.caseTopicsSubsection.introduction &&
                parse(marked(this.props.data.constitutionalCasesFromAroundTheWorldSection.caseTopicsSubsection.introduction))
              }
            </Segment>
            {
              !this.props.isMobile && <CaseTopics />
            }
            {
              this.props.isMobile &&
              <Segment basic style={{ padding: "0em" }}>
                <Accordion fluid styled style={{ marginTop: "3em" }}>
                  {caseTopics.map((item, i) => (
                    <>
                      <Accordion.Title
                        index={i}
                        // active={!!this.state.activeCaseTopicIndices[i]}
                        active={true}
                        onClick={this.toggleActiveCaseTopics}
                        style={{ fontSize: "1.1em" }}
                      >
                        <Icon name="dropdown" />
                        {item.caseTopic.title}
                      </Accordion.Title>
                      <Accordion.Content
                        active={!!this.state.activeCaseTopicIndices[i]}
                      >
                        <Segment basic className="MkdContent"
                          style={{
                            paddingLeft: this.props.isMobile && "0em",
                            paddingRight: this.props.isMobile && "0em",
                            overflow: "auto",
                          }}
                          children={
                            <>
                              <Image rounded bordered floated="right" size="medium"
                                src={item.caseTopic.image}
                              />
                              {item.caseTopic.cases && parse(marked(item.caseTopic.cases))}
                            </>
                          }
                        />
                      </Accordion.Content>
                    </>
                  ))}
                </Accordion>
              </Segment>
            }
          </Segment>
        </Accordion.Content>
        <Accordion.Title
          // active={this.state.isCaseResourcesAndDatabasesSubsectionActive}
          active={true}
          onClick={() => this.setState({
            isCaseResourcesAndDatabasesSubsectionActive: !this.state.isCaseResourcesAndDatabasesSubsectionActive
          })}
          style={{ fontSize: "1.1em" }}
        >
          <Icon name="dropdown" />
          {this.props.data.constitutionalCasesFromAroundTheWorldSection.caseResourcesAndDatabasesSubsection.title}
        </Accordion.Title>
        <Accordion.Content
          active={this.state.isCaseResourcesAndDatabasesSubsectionActive}
        >
          <Segment basic padded={!this.props.isMobile} style={{ paddingLeft: this.props.isMobile && "0em", paddingRight: this.props.isMobile && "0em" }}>
            <Segment basic className="MkdContent" style={{ padding: "0em" }}>
              {
                this.props.data.constitutionalCasesFromAroundTheWorldSection.caseResourcesAndDatabasesSubsection.image &&
                <Image rounded bordered floated="left" size="tiny"
                  src={this.props.data.constitutionalCasesFromAroundTheWorldSection.caseResourcesAndDatabasesSubsection.image}
                />
              }
              {
                this.props.data.constitutionalCasesFromAroundTheWorldSection.caseResourcesAndDatabasesSubsection.introduction &&
                parse(marked(this.props.data.constitutionalCasesFromAroundTheWorldSection.caseResourcesAndDatabasesSubsection.introduction))
              }
            </Segment>
            <Segment basic style={{ padding: "0em" }}>
              <Accordion fluid styled style={{ marginTop: "3em" }}>
                {caseResourcesAndDatabases.map((item, i) => (
                  <>
                    <Accordion.Title
                      index={i}
                      // active={!!this.state.activeCaseResourcesAndDatabasesIndices[i]}
                      active={true}
                      onClick={this.toggleActiveCaseResourcesAndDatabases}
                      style={{ fontSize: "1.1em" }}
                    >
                      <Icon name="dropdown" />
                      {item.title}
                    </Accordion.Title>
                    <Accordion.Content
                      active={!!this.state.activeCaseResourcesAndDatabasesIndices[i]}
                    >
                      <Segment basic
                        className="MkdContent"
                        style={{
                          paddingLeft: this.props.isMobile && "0em",
                          paddingRight: this.props.isMobile && "0em",
                          overflow: "auto",
                        }}
                        children={
                          <>
                            <Image rounded bordered floated="right" size="medium"
                              src={item.image}
                            />
                            {item.briefDescription && parse(marked(item.briefDescription))}
                          </>
                        }
                      />
                    </Accordion.Content>
                  </>
                ))}
              </Accordion>
            </Segment>
          </Segment>
        </Accordion.Content>
      </Accordion >
    )
  }
}

class CaseTopics extends React.Component {
  state = {
    contentSegment: this.renderContentSegment(caseTopics[0].caseTopic, 0)
  }
  toggleTopicVisibility = (caseTopic, index) => () => {
    this.setState({
      contentSegment: this.renderContentSegment(caseTopic, index)
    })
  }
  renderContentSegment(caseTopic, index) {
    return (
      <Segment padded style={{ height: "40em", overflow: "auto" }}>
        <Header as="h3" dividing textAlign="center">{caseTopic.title}</Header>
        <Segment basic className="MkdContent"
          style={{
            paddingLeft: this.props.isMobile && "0em",
            paddingRight: this.props.isMobile && "0em",
          }}
          children={
            <>
              <Image rounded bordered floated="right" size="medium"
                src={caseTopic.image}
              />
              {caseTopic.cases && parse(marked(caseTopic.cases))}
            </>
          }
        />
      </Segment >
    )
  }
  render() {
    return (
      <Sidebar.Pushable as={Grid}
        style={{
          margin: "0em"
        }}
        children={
          <>
            <Grid.Column width={2}
              style={{
                padding: "0em",
              }}
              children={
                <Sidebar
                  as={Menu}
                  animation="push"
                  icon="labeled"
                  inverted
                  vertical
                  visible
                  width="thin"
                  style={{
                    backgroundColor: "cornflowerblue",
                    position: "unset",
                  }}
                  content={
                    caseTopics.map((item, i) => (
                      <Menu.Item as="a"
                        onClick={this.toggleTopicVisibility(item.caseTopic, i)}
                        style={{ lineHeight: "1.5em", textAlign: "left" }}
                        content={item.caseTopic.title}
                      />
                    ))
                  }
                />
              }
            />
            <Grid.Column width={14}
              style={{
                padding: "0em",
                paddingLeft: "2em",
              }}
              children={
                <Sidebar.Pusher
                  content={this.state.contentSegment}
                />
              }
            />
          </>
        }
      />
    )
  }
}
